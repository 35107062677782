import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { enqueueSnackbar } from "notistack";
import {
  getQueryIntent,
  getEntity,
  getQueryTabularData,
  chatWithAI,
  getChatMetaData,
  getPidResponse,
} from "../core/repo/chatRepo";
import { createSession } from "../core/repo/sessionRepo";
import { getAIModel, getUserDetails } from "../core/storage/localStorage";
import { usePostHog } from "posthog-js/react";
import {
  ASK_QUESTION_SUCCESS,
  ASK_QUESTION_FAILURE,
  FETCH_METADATA_SUCCESS,
  FETCH_METADATA_FAILURE,
} from "../utils/posthogEvents";
import { updateUsage } from "../utils/updateUsage";

export const useQueryHandler = (onMessageUpdate = () => {}, sessionId = null) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const posthog = usePostHog();
  const [tempQandA, setTempQandA] = useState(null);
  const [isMetadataLoading, setIsMetadataLoading] = useState(false);
  const { completedFiles } = useSelector((state) => state.files);
  const { assistantMode } = useSelector((state) => state.account);
  const user = getUserDetails();
  const model = getAIModel();

  const streamApiError = (err, sessId, queryId, query) => {
    let errMessage = "Could not connect to server. Please try again.";
    if (err["response"]) {
      const { status } = err["response"];
      switch (status) {
        case 500:
          errMessage = "Server Error";
          break;
        case 502:
          errMessage = "Service Unavailable";
          break;
      }
    }
    enqueueSnackbar(errMessage, { variant: "error" });
    posthog.capture(ASK_QUESTION_FAILURE);

    const { userQuery, aiResponse } = getDummyAnswerAndQuery(
      sessId,
      queryId,
      query,
      "",
      "stream_api_fail"
    );

    onMessageUpdate?.({ query: userQuery, answer: aiResponse });
    setTempQandA(null);
    return "stream_api_error";
  };

  const handleEntity = async (sessId, queryId, query, queryIntent, fileIds, threadId, domainId) => {
    try {
      const entities = await dispatch(
        getEntity(queryIntent, query, sessId, queryId, threadId, true, fileIds, model, domainId)
      );

      const { query_object, answer_object } = entities;
      onMessageUpdate?.({ query: query_object, answer: answer_object });
      updateUsage();
    } catch (err) {
      streamApiError(err, sessId, queryId, query);
    }
    setTempQandA(null);
  };

  const handleQueryTabularData = async (
    sessId,
    queryId,
    query,
    queryIntent,
    fileIds,
    threadId,
    domainId
  ) => {
    try {
      const entities = await dispatch(
        getQueryTabularData(
          queryIntent,
          query,
          sessId,
          queryId,
          threadId,
          true,
          fileIds,
          model,
          domainId
        )
      );

      const { query_object, answer_object } = entities;
      onMessageUpdate?.({ query: query_object, answer: answer_object });
      updateUsage();
    } catch (err) {
      streamApiError(err, sessId, queryId, query);
    }
    setTempQandA(null);
  };

  const streamMessages = async (sessId, queryId, query, fileIds, threadId, domainId) => {
    let tempQueryResponse = "";
    try {
      await chatWithAI(
        query,
        sessId,
        queryId,
        threadId,
        true,
        fileIds,
        model,
        domainId,
        assistantMode,
        (text) => {
          if (text) {
            setTempQandA({
              query,
              queryId,
              response: text,
            });
            tempQueryResponse = text;
          }
        }
      );
      posthog.capture(ASK_QUESTION_SUCCESS);
      updateUsage();
    } catch (err) {
      return streamApiError(err, sessId, queryId, query);
    }

    setIsMetadataLoading(true);
    try {
      const response = await getChatMetaData(queryId);
      const { ai_response, user_query } = response;
      onMessageUpdate?.({ query: user_query, answer: ai_response });
      posthog.capture(FETCH_METADATA_SUCCESS);
    } catch (e) {
      const { userQuery, aiResponse } = getDummyAnswerAndQuery(
        sessId,
        queryId,
        query,
        tempQueryResponse,
        "metadata_api_fail"
      );
      onMessageUpdate?.({ query: userQuery, answer: aiResponse });
      posthog.capture(FETCH_METADATA_FAILURE, {
        query: query,
        messageId: queryId,
      });
      console.log(e);
    }
    setIsMetadataLoading(false);
    setTempQandA(null);
  };

  const handlePID = async (query, sessId, queryId, threadId, fileIds, model, domainId) => {
    try {
      const response = await dispatch(
        getPidResponse({}, query, sessId, queryId, threadId, true, fileIds, model, domainId)
      );
      let { query_object, answer_object } = response;
      onMessageUpdate?.({ query: query_object, answer: answer_object });
      updateUsage();
    } catch (err) {
      streamApiError(err, sessId, queryId, query);
    }
    setTempQandA(null);
  };

  const handleQueryIntent = async (
    query,
    isNewSession = true,
    taggedFiles = [],
    taggedAgent = null,
    chatAgent = null,
    changeUrl = true
  ) => {
    let queryId = uuidv4();
    let qna = {
      query: query,
      response: null,
      queryId: queryId,
      chatAgent: chatAgent,
    };
    let threadId = "";

    // Handle tagged files
    let taggedFileIds = [];

    taggedFiles.forEach((file) => {
      taggedFileIds.push(file.id);
    });

    if (taggedFileIds.length === 0) {
      taggedFileIds = completedFiles.map((f) => f.id);
    }

    let domainId = taggedAgent && taggedAgent.id ? taggedAgent.id : null;

    setTempQandA(qna);

    let sessId = sessionId;
    try {
      if (isNewSession) {
        const response = await dispatch(createSession(user["id"]));
        sessId = response["id"];
        if (changeUrl) {
          history.push(`/assistant/${sessId}`);
        }
      }

      // If chatAgent is pid_assist, skip query intent and go directly to handlePID
      if (chatAgent === "pid_assist") {
        return handlePID(query, sessId, queryId, threadId, taggedFileIds, model, domainId);
      }

      const intentResponse = await dispatch(
        getQueryIntent(query, sessId, queryId, threadId, true, taggedFileIds, model, domainId)
      );

      const { intent } = intentResponse;

      if (intent === "document_request") {
        return handleEntity(
          sessId,
          queryId,
          query,
          intentResponse,
          taggedFileIds,
          threadId,
          domainId
        );
      } else if (
        intent === "information_retrieval" ||
        intent === "procedural_query" ||
        intent === "open_ended_analysis" ||
        intent === "clarification_needed" ||
        intent === "out_of_scope" ||
        intent === "data_lookup" ||
        !intent
      ) {
        return streamMessages(sessId, queryId, query, taggedFileIds, threadId, domainId);
      } else if (intent === "data_lookup") {
        return handleQueryTabularData(
          sessId,
          queryId,
          query,
          intentResponse,
          taggedFileIds,
          threadId,
          domainId
        );
      } else {
        return streamMessages(sessId, queryId, query, taggedFileIds, threadId, domainId);
      }
    } catch (err) {
      return streamApiError(err, sessId, queryId, query);
    }
  };

  return {
    handleQueryIntent,
    tempQandA,
    isMetadataLoading,
  };
};

function getDummyAnswerAndQuery(sessionId, queryId, query, content, apiFailType) {
  const userQuery = {
    content: query,
    content_object: { citations: [], file_ids: [], text: query },
    conversation_id: sessionId,
    thread_id: "",
    threads: [],
    role: "user",
    question_id: "",
    message_id: queryId,
    created_at: new Date().toISOString(),
  };
  const aiResponse = {
    content: content,
    content_object: {
      citations: [],
      file_ids: [],
      text: content,
    },
    conversation_id: sessionId,
    thread_id: "",
    threads: [],
    role: "assistant",
    question_id: queryId,
    message_id: uuidv4(),
    created_at: new Date().toDateString(),
    error: apiFailType,
  };
  return { userQuery, aiResponse };
}
