import React, { useState } from "react";
import {
  Button,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  AddRounded,
  ChatRounded,
  ImageSearchRounded,
  TimelineRounded,
} from "@mui/icons-material";
import { GradientIconAccent } from "../../../components/GradientIcon";
import {
  CHECKLIST_DIAGRAM_MODE_URL,
  DATA_ANALYSIS_MODE_URL,
  Q_A_MODE_URL,
} from "../../../utils/routes";
import { makeStyles } from "tss-react/mui";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getSessionMessagesSuccess,
  getSessionsSuccess,
} from "../../../core/events/sessionEvents";

const useStyles = makeStyles()((theme) => ({
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
}));

export const chatModes = [
  {
    label: "QnA",
    key: "qna",
    icon: <GradientIconAccent Icon={ChatRounded} sx={{ fontSize: 20 }} />,
    originalIcon: ChatRounded,
    color: "purple",
    path: Q_A_MODE_URL,
  },
  // {
  //   label: "Checklist/Diagram",
  //   key: "checklist_diagram",
  //   icon: <ImageSearchRounded fontSize="small" sx={{ color: "#2196F3" }} />,
  //   originalIcon: ImageSearchRounded,
  //   color: "blue",
  //   path: CHECKLIST_DIAGRAM_MODE_URL,
  // },
  {
    label: "Data Analysis",
    key: "data_analysis",
    icon: <TimelineRounded fontSize="small" sx={{ color: "#4CAF50" }} />,
    originalIcon: TimelineRounded,
    color: "green",
    path: DATA_ANALYSIS_MODE_URL,
  },
];

function NewSessionButton({ expanded }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickChatMode = (chatMode) => {
    history.push(chatMode["path"]);
    if (location.pathname !== chatMode["path"]) {
      dispatch(getSessionsSuccess([]));
      dispatch(getSessionMessagesSuccess([]));
    }
    handleClose();
  };

  return (
    <Box px={1.5} display={"flex"} justifyContent={"center"}>
      <Tooltip title={"New Session"} arrow disableHoverListener={expanded}>
        <Button
          classes={{ startIcon: !expanded ? classes.startIcon : null }}
          fullWidth={expanded}
          variant="contained"
          color="primary"
          startIcon={<AddRounded />}
          onClick={handleClick}
          sx={{
            minWidth: 30,
            padding: expanded ? "6px 16px" : "8px",
          }}>
          {expanded ? "New Session" : ""}
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: { sx: { borderRadius: 2, minWidth: 100, mt: 1 } },
        }}>
        {chatModes.map((chatMode) => {
          const { key, label, icon, path } = chatMode;
          return (
            <MenuItem
              key={key}
              onClick={() => handleClickChatMode(chatMode)}
              selected={location.pathname.startsWith(path)}>
              <ListItemIcon>{icon}</ListItemIcon>
              <Typography>{label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default NewSessionButton;
