import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import markdownit from "markdown-it";
import { Box, useTheme } from "@mui/material";

function HighlightText({ editorRef, message, typeOfAnswer, highlightedSections }) {
  const theme = useTheme();
  const md = markdownit();
  const html = md.render(message);
  const [isEditorReady, setIsEditorReady] = useState(false);

  useEffect(() => {
    // Wait for editor to be ready
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();
      setIsEditorReady(true);
    }
  }, []);

  useEffect(() => {
    if (
      isEditorReady &&
      editorRef.current &&
      highlightedSections &&
      highlightedSections.length > 0
    ) {
      const quill = editorRef.current.getEditor();
      console.log("Applying highlights:", {
        highlightedSections,
        contentLength: quill.getLength(),
        content: quill.getText(),
      });

      // Clear any existing highlights first
      quill.formatText(0, quill.getLength(), "background", false);

      // Set content and wait for it to be loaded
      setTimeout(() => {
        highlightedSections.forEach((section) => {
          try {
            const startIndex = parseInt(section.start_index);
            const length = section.text.length;
            console.log("Applying highlight:", { startIndex, length, text: section.text });
            if (!isNaN(startIndex) && length > 0) {
              quill.formatText(startIndex, length, "background", "#FFF59D");
            }
          } catch (error) {
            console.error("Error applying highlight:", error);
          }
        });
      }, 100);
    }
  }, [isEditorReady, highlightedSections, message]);

  const handleSelectionChange = (range, source) => {
    if (source !== "user" || !range || !editorRef.current || typeOfAnswer !== "part_of_answer") {
      return;
    }
    editorRef.current.editor.format("background", "#FFF59D");
  };

  return (
    <Box
      width={"100%"}
      height={400}
      overflow={"scroll"}
      padding={1.5}
      borderRadius={3}
      sx={{
        background:
          typeOfAnswer === "complete_answer" ? theme.palette.background.light : theme.palette.paper,
      }}
    >
      <ReactQuill
        readOnly
        ref={editorRef}
        theme={null}
        defaultValue={html}
        onChangeSelection={handleSelectionChange}
        style={{ height: "100%", overflowY: "auto" }}
      />
    </Box>
  );
}

export default HighlightText;
