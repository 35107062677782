import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Chip,
  Typography,
  useTheme,
  Divider,
  IconButton,
  Card,
  CardContent,
  CardActionArea,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  ThumbDownAltRounded,
  ThumbUpAltRounded,
  CloseRounded,
  CheckCircleOutlineRounded,
  CancelOutlined,
  ArrowOutward,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateFeedbackStatusSuccess } from "../../core/events/sessionEvents";
import sessionService from "../../core/api/sessionService";
import { useSnackbar } from "notistack";
import HighlightText from "../AskAI/components/Feedback/HighlightText";
import { useHistory } from "react-router-dom";
import { BOOKMARKS_URL, CHECKLIST_DIAGRAM_MODE_URL } from "../../utils/routes";
import { getFileIconImage } from "../Files/FilesView/Results/FilesList/GridView";

const useStyles = makeStyles()((theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  feedbackHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
    position: "relative",
  },
  feedbackContent: {
    marginTop: theme.spacing(2),
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  feedbackChip: {
    margin: theme.spacing(0.5),
  },
  highlightedSection: {
    backgroundColor: theme.palette.primary.lighter,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  questionContent: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  answerContent: {
    color: theme.palette.text.secondary,
  },
  dateText: {
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(4),
  },
  ratingIcon: {
    marginRight: theme.spacing(1),
  },
  partialText: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(0.5),
  },
  highlightedText: {
    backgroundColor: theme.palette.warning.main,
    padding: "2px 4px",
    borderRadius: "2px",
    color: theme.palette.common.black,
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  statusChip: {
    marginLeft: theme.spacing(1),
  },
  sourcesContainer: {
    marginTop: theme.spacing(2),
  },
  citationCard: {
    border: `1px solid ${theme.palette.divider}`,
    marginRight: 16,
    marginBottom: 4,
    borderRadius: 12,
    minWidth: 300,
    maxWidth: 300,
  },
  text: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
}));

function ViewFeedbackDetail({ feedback, open, onClose }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const editorRef = useRef();
  const history = useHistory();
  const { files } = useSelector((state) => state.files);

  if (!feedback) return null;

  const isPositive = feedback.rating === "positive" || feedback.rating === "good";

  const formatDate = (dateString) => {
    if (!dateString) return "Unknown date";
    try {
      console.log("Raw date string:", dateString);

      // Ensure the date string is properly formatted for UTC
      let date;
      if (dateString.includes("T")) {
        // If it's an ISO string format
        date = new Date(dateString);
      } else {
        // Try to parse as UTC date
        date = new Date(dateString + "T00:00:00Z");
      }

      console.log("Parsed date object:", date);

      if (isNaN(date.getTime())) {
        console.error("Invalid date parsing result for:", dateString);
        return "Invalid date";
      }

      const formatted = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(date);

      console.log("Formatted date:", formatted);
      return formatted;
    } catch (error) {
      console.error("Error formatting date:", error, "for date string:", dateString);
      return "Invalid date";
    }
  };

  const formatFeedbackTags = (feedbackString) => {
    if (!feedbackString) return [];
    try {
      return feedbackString
        .split(",")
        .map((tag) => tag.trim())
        .filter(Boolean);
    } catch (error) {
      console.error("Error formatting feedback tags:", error);
      return [];
    }
  };

  const handleUpdateStatus = async (status) => {
    if (!feedback || !feedback.message_id) {
      enqueueSnackbar("Cannot update feedback: missing message ID", { variant: "error" });
      return;
    }

    try {
      await sessionService.updateFeedbackStatus(feedback.message_id, status);
      dispatch(updateFeedbackStatusSuccess(feedback.message_id, status));
      enqueueSnackbar(`Feedback ${status} successfully`, { variant: "success" });
      onClose();
    } catch (error) {
      console.error("Error updating feedback status:", error);
      enqueueSnackbar(`Failed to ${status} feedback`, { variant: "error" });
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "success";
      case "rejected":
        return "error";
      default:
        return "default";
    }
  };

  const handleOpenMessage = () => {
    if (feedback.message_id && feedback.conversation_id) {
      let url = `/qna/sessions/${feedback.conversation_id}`;
      if (feedback.mode === "data_analysis") {
        url = `/data-analysis/sessions/${feedback.conversation_id}`;
      } else if (feedback.mode === "checklist_diagram") {
        url = CHECKLIST_DIAGRAM_MODE_URL;
      }
      history.push({
        pathname: url,
        search: "?" + new URLSearchParams({ messageId: feedback.message_id }).toString(),
      });
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: "90vh",
          maxHeight: "90vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.feedbackHeader}>
          <Box display="flex" flexDirection="column" flex={1}>
            <Box display="flex" alignItems="center">
              {isPositive ? (
                <ThumbUpAltRounded
                  className={classes.ratingIcon}
                  sx={{ color: theme.palette.success.main }}
                />
              ) : (
                <ThumbDownAltRounded
                  className={classes.ratingIcon}
                  sx={{ color: theme.palette.error.main }}
                />
              )}
              <Typography variant="h6">
                {feedback.question_content || "No question content"}
                {feedback.approval_status && (
                  <Chip
                    label={feedback.approval_status}
                    color={getStatusColor(feedback.approval_status)}
                    size="small"
                    className={classes.statusChip}
                  />
                )}
              </Typography>
            </Box>
            <Typography className={classes.dateText}>{formatDate(feedback.updated_at)}</Typography>
            {!isPositive && feedback.partial && (
              <Typography variant="caption" className={classes.partialText}>
                Partially Incorrect
              </Typography>
            )}
          </Box>
          <IconButton onClick={onClose} size="small" className={classes.closeButton}>
            <CloseRounded />
          </IconButton>
        </Box>

        <Divider />

        <Box className={classes.feedbackContent}>
          <Typography variant="subtitle2">Feedback:</Typography>

          {feedback.feedback && (
            <Box mt={0}>
              <Box mt={0}>
                {formatFeedbackTags(feedback.feedback).map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    className={classes.feedbackChip}
                    size="small"
                    color={isPositive ? "success" : "error"}
                    variant="outlined"
                  />
                ))}
              </Box>
            </Box>
          )}

          {feedback.description && (
            <Box mt={2}>
              <Typography variant="subtitle2">Comment:</Typography>
              <Typography>{feedback.description}</Typography>
            </Box>
          )}

          {feedback.answer_content && (
            <Box mt={2} flex={1}>
              <Typography variant="subtitle2">Answer:</Typography>
              <Box>
                <HighlightText
                  editorRef={editorRef}
                  message={feedback.answer_content}
                  typeOfAnswer={"complete_answer"}
                  highlightedSections={feedback.highlighted_sections}
                />
              </Box>
              {feedback.answer_content_object?.citations?.length > 0 && (
                <Box className={classes.sourcesContainer}>
                  <Typography variant="subtitle2" gutterBottom>
                    Sources:
                  </Typography>
                  <Box sx={{ overflowX: "auto", display: "flex", pb: 1 }}>
                    {feedback.answer_content_object.citations.map((citation, i) => {
                      const { section, file_id, sub_title } = citation;
                      const file = files.find((f) => f["id"] === file_id);
                      return (
                        <Card
                          elevation={1}
                          key={i}
                          className={classes.citationCard}
                          onClick={handleOpenMessage}
                        >
                          <CardActionArea sx={{ height: "100%" }}>
                            <CardContent sx={{ height: "100%" }}>
                              <Box height={"100%"} display={"flex"} flexDirection={"column"}>
                                <Box flex={1}>
                                  <Typography variant="body2" className={classes.text}>
                                    {section || "."}
                                  </Typography>
                                </Box>
                                <Box flex={1} mt={0.5}>
                                  <Typography
                                    variant="caption"
                                    color={"textSecondary"}
                                    className={classes.text}
                                  >
                                    {sub_title || " "}
                                  </Typography>
                                </Box>
                                <Box mb={1.5} />
                                {file && (
                                  <Box>
                                    <Tooltip title={file["name"]}>
                                      <Chip
                                        sx={{ paddingLeft: 0.5 }}
                                        label={
                                          <Typography variant="caption">{file["name"]}</Typography>
                                        }
                                        size="small"
                                        icon={
                                          <img
                                            src={getFileIconImage(file["file_type"])}
                                            alt={file["name"]}
                                            width={16}
                                            height={16}
                                          />
                                        }
                                        deleteIcon={<ArrowOutward />}
                                        onDelete={() => {}}
                                      />
                                    </Tooltip>
                                  </Box>
                                )}
                              </Box>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleOpenMessage}>
          Open Message
        </Button>
        <Box flex={1} />
        <Button
          onClick={() => handleUpdateStatus("rejected")}
          color="error"
          disabled={feedback.approval_status === "rejected"}
        >
          Reject
        </Button>
        <Button
          onClick={() => handleUpdateStatus("approved")}
          color="success"
          disabled={feedback.approval_status === "approved"}
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewFeedbackDetail;
