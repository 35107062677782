import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Box,
  InputBase,
  IconButton,
  Tooltip,
  Chip,
  Typography,
  useTheme,
  Divider,
  alpha,
} from "@mui/material";
import {
  KeyboardVoice,
  CloseRounded,
  AttachFileRounded,
  SendRounded,
  FilterListRounded,
} from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { Audio } from "./Audio";
import NewSessionDialog from "./NewSessionDialog";
import { getFileIconImage } from "../../../Files/FilesView/Results/FilesList/GridView";
import ProModeSwitch from "./ProModeSwitch";
import { chatAgents } from "./NewSessionDialog";
import {
  getChatAgentLocalStorage,
  setChatAgentLocalStorage,
} from "../../../../core/storage/localStorage";
import { FilesDialog } from "./TagFileDialog";
import { useDispatch } from "react-redux";
import { toggleAssistantModeSuccess } from "../../../../core/events/accountEvents";

const useStyles = makeStyles()((theme, params) => ({
  container: {
    width: "100%",
    borderRadius: 16,
    border: `1px solid ${theme.palette.divider}`,
    background: "#FAFAFA",
    "&:focus-within": {
      border: `2px solid ${params.borderColor}`,
      margin: -1,
    },
  },
  activeSendBtn: {
    borderRadius: 8,
    cursor: "pointer",
    background: theme.palette.light,
  },
  disabledSendBtn: {
    borderRadius: 8,
    marginLeft: 12,
    cursor: "not-allowed",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function QueryBox({
  queryContainerHeight = 0,
  isStreaming,
  handleQuery,
  alwaysCreateNewSession = false,
  queryBoxContainerRef,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const queryRef = useRef(null);
  const [audioMode, setAudioMode] = useState(false);
  const [query, setQuery] = useState("");
  const [openFilesDialog, setOpenFilesDialog] = useState(false);
  const [isNewSessionDialogOpen, setIsNewSessionDialogOpen] = useState(false);
  const [isNewSession, setIsNewSession] = useState(false);
  const chatAgentLocalStorage = getChatAgentLocalStorage();
  const chatAgentObjLocalStorage = chatAgents.find(
    (agent) => agent.key === chatAgentLocalStorage
  );
  const [chatAgent, setChatAgent] = useState(
    chatAgentObjLocalStorage ? chatAgentObjLocalStorage : chatAgents[0]
  );
  const { classes } = useStyles({ borderColor: chatAgent.color });
  const [taggedAgent, setTaggedAgent] = useState(null);
  const [taggedFiles, setTaggedFiles] = useState([]);

  useEffect(() => {
    if (queryRef.current) {
      queryRef.current.focus();
    }
  }, [chatAgent, taggedFiles, queryRef]);

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleQuerySubmit = () => {
    const queryValue = query.trim();
    if (!queryValue) return;
    setQuery("");
    handleQuery(
      queryValue,
      alwaysCreateNewSession || isNewSession,
      taggedFiles,
      taggedAgent,
      alwaysCreateNewSession ? "qna" : chatAgent.key,
      !alwaysCreateNewSession
    );
    setIsNewSession(false);
  };

  const handleQueryKeyDown = (e) => {
    const { key, shiftKey } = e;
    const queryValue = query.trim();
    if (key === "Enter" && !shiftKey && queryValue && !isStreaming) {
      e.preventDefault();
      handleQuerySubmit();
    }
  };

  const handleFilesDialogOpen = () => {
    setOpenFilesDialog(true);
  };

  const handleFilesDialogClose = () => {
    setOpenFilesDialog(false);
  };

  const removeTaggedFile = (fileName) => {
    setTaggedFiles((prevTaggedFiles) =>
      prevTaggedFiles.filter((f) => f.name !== fileName)
    );
  };

  const addTaggedFiles = (files) => {
    setTaggedFiles((prevTaggedFiles) => [...prevTaggedFiles, ...files]);
  };

  const addTaggedAgent = (agent) => {
    setTaggedAgent(agent);
  };

  const handleChatAgentChange = (agent) => {
    setChatAgent(agent);
    setChatAgentLocalStorage(agent.key);
    setIsNewSession(true);
    setIsNewSessionDialogOpen(false);
    if (agent.key !== "qna") {
      dispatch(toggleAssistantModeSuccess(false));
    }
  };

  return (
    <Box ref={queryBoxContainerRef} width={"100%"} display={"flex"}>
      {!alwaysCreateNewSession && (
        <NewSessionDialog
          queryContainerHeight={queryContainerHeight}
          onChatAgentChange={handleChatAgentChange}
          isNewSessionDialogOpen={isNewSessionDialogOpen}
          setIsNewSessionDialogOpen={setIsNewSessionDialogOpen}
        />
      )}
      <Box
        visibility={isNewSessionDialogOpen ? "hidden" : "visible"}
        width={"100%"}
        className={!alwaysCreateNewSession ? classes.container : null}
        p={!chatAgent ? 1 : 0}>
        {chatAgent && !alwaysCreateNewSession && (
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={1}
            py={0.5}
            borderBottom={`1px solid ${theme.palette.divider}`}
            sx={{
              cursor: "pointer",
              background: alpha(theme.palette.accent, 0.1),
              borderTopLeftRadius: 14,
              borderTopRightRadius: 14,
            }}
            onClick={() => setIsNewSessionDialogOpen(true)}>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <chatAgent.icon
                fontSize="small"
                sx={{ color: chatAgent.color }}
              />
              <Typography fontSize={13} fontWeight={500}>
                {chatAgent.name}
              </Typography>
            </Box>
            <IconButton size="small">
              <CloseRounded fontSize="small" />
            </IconButton>
          </Box>
        )}
        <Box p={chatAgent ? 1 : 0}>
          {(taggedAgent || taggedFiles.length > 0) && (
            <Box
              display={"flex"}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={1}
              mb={1}>
              {taggedAgent && (
                <Tooltip title={taggedAgent.name} placement="top">
                  <Chip
                    size="small"
                    variant="filled"
                    label={taggedAgent.name}
                    onDelete={() => addTaggedAgent(null)}
                    sx={{ maxWidth: 150 }}
                  />
                </Tooltip>
              )}
              {taggedFiles.map((file) => {
                return (
                  <Tooltip title={file.name} key={file.name} placement="top">
                    <Chip
                      size="small"
                      variant="outlined"
                      label={file.name}
                      icon={
                        <img
                          src={getFileIconImage(file.file_type)}
                          alt="file_icon"
                          width={16}
                          height={16}
                          style={{ borderRadius: "50%" }}
                        />
                      }
                      onDelete={() => removeTaggedFile(file.name)}
                      sx={{ maxWidth: 150 }}
                    />
                  </Tooltip>
                );
              })}
            </Box>
          )}
          {audioMode ? (
            <Box width={"100%"} mr={1}>
              <Audio
                recordingError={(e) => {
                  setAudioMode(false);
                  enqueueSnackbar("Permission denied", { variant: "error" });
                }}
                recordingCancelled={(e) => {
                  setAudioMode(false);
                  enqueueSnackbar("Voice query cancelled", {
                    variant: "warning",
                  });
                }}
                recordingText={(text) => {
                  setAudioMode(false);
                  if (text) {
                    setQuery(text["text"]);
                  } else {
                    enqueueSnackbar("Error processing voice query", {
                      variant: "error",
                    });
                  }
                }}
              />
            </Box>
          ) : (
            <InputBase
              inputRef={queryRef}
              fullWidth
              value={query}
              onChange={handleQueryChange}
              onKeyDown={handleQueryKeyDown}
              placeholder="Search, or ask anything..."
              multiline
              minRows={2}
              maxRows={4}
              autoFocus
            />
          )}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"} gap={0.5}>
              <Box
                p={0.5}
                borderRadius={1}
                display={"flex"}
                alignItems={"center"}
                gap={0.25}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: theme.palette.action.hover },
                }}
                onClick={handleFilesDialogOpen}>
                <FilterListRounded
                  fontSize="small"
                  sx={{ color: theme.palette.action.active }}
                />
                <Typography
                  fontSize={13}
                  fontWeight={500}
                  color={"text.secondary"}>
                  Files
                </Typography>
              </Box>
              {chatAgent.key === "qna" && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ height: 24, alignSelf: "center", marginX: 0.5 }}
                  />
                  <ProModeSwitch />
                </>
              )}
            </Box>
            {query ? (
              <IconButton
                size="small"
                onClick={handleQuerySubmit}
                disabled={!(query && !isStreaming)}
                className={
                  query && !isStreaming
                    ? classes.activeSendBtn
                    : classes.disabledSendBtn
                }>
                <SendRounded
                  fontSize="small"
                  sx={{
                    color: !query || isStreaming ? "inherit" : "#ffffff",
                  }}
                />
              </IconButton>
            ) : (
              <IconButton
                onClick={(e) => setAudioMode(true)}
                disabled={audioMode || isStreaming}
                size="small">
                <Tooltip title="Speak your question">
                  <KeyboardVoice fontSize="small" />
                </Tooltip>
              </IconButton>
            )}
          </Box>
        </Box>
        {openFilesDialog && (
          <FilesDialog
            onClose={handleFilesDialogClose}
            taggedFiles={taggedFiles}
            addTaggedFiles={addTaggedFiles}
          />
        )}
      </Box>
    </Box>
  );
}

export default QueryBox;
