/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Error404View from "./views/404/Error404View";
import Account from "./views/Account";
import AuthGuard from "./components/AuthGuard";
import LoginGuard from "./components/LoginGuard";
import Files from "./views/Files";
import DashboardLayout from "./views/DashboardLayout";
import Login from "./views/Auth/Login";
import Authenticate from "./views/Auth/Authenticate";
import EmailVerification from "./views/Auth/EmailVerification";
import {
  FILES_URL,
  AUTHENTICATE_URL,
  LOGIN_URL,
  ERROR_URL,
  EMAIL_VERIFICATION_URL,
  ACCOUNT_KEY_URL,
  BOOKMARKS_URL,
  ASSISTANT_URL,
  FEEDBACK_URL,
} from "./utils/routes";
import Bookmarks from "./views/Bookmarks/Bookmarks";
import Assistant from "./views/Assistant/Assistant";
import History from "./views/History/History";
import AssistantRedirect from "./views/Assistant";
import Feedback from "./views/Feedback";

const routesConfig = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to={ASSISTANT_URL} />,
  },
  {
    exact: true,
    path: ERROR_URL,
    component: () => <Error404View />,
  },
  ...(process.env.REACT_APP_ENV === "staging"
    ? [
        {
          exact: true,
          path: ACCOUNT_KEY_URL,
          guard: LoginGuard,
          component: () => <Account />,
        },
      ]
    : []),
  {
    exact: true,
    path: LOGIN_URL,
    guard: LoginGuard,
    component: () => <Login />,
  },
  {
    exact: true,
    path: EMAIL_VERIFICATION_URL,
    guard: LoginGuard,
    component: () => <EmailVerification />,
  },
  {
    exact: true,
    path: AUTHENTICATE_URL,
    component: () => <Authenticate />,
  },
  {
    path: "",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: ASSISTANT_URL,
        component: () => <AssistantRedirect />,
      },
      {
        exact: true,
        path: `${ASSISTANT_URL}/:sessionId`,
        component: () => <Assistant />,
      },
      {
        exact: true,
        path: "/history",
        component: () => <History />,
      },
      {
        exact: true,
        path: BOOKMARKS_URL,
        component: () => <Bookmarks />,
      },
      {
        exact: true,
        path: FEEDBACK_URL,
        component: () => <Feedback />,
      },
      {
        exact: true,
        path: FILES_URL,
        component: () => <Files />,
      },
      {
        component: () => <Redirect to={ERROR_URL} />,
      },
    ],
  },
  {
    component: () => <Redirect to={ERROR_URL} />,
  },
];

const renderRoutes = (routes) => (
  <Switch>
    {routes.map((route, i) => {
      const Guard = route.guard || Fragment;
      const Layout = route.layout || Fragment;
      const Component = route.component;

      return (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <Guard>
              <Layout>
                {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
              </Layout>
            </Guard>
          )}
        />
      );
    })}
  </Switch>
);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
