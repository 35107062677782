import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  MenuItem,
  ListItemIcon,
  useTheme,
  Divider,
  IconButton,
  Checkbox,
  TextField,
  Tooltip,
  Grid,
  Chip,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  SearchRounded,
  CloseRounded,
  ArrowDropDownRounded,
} from "@mui/icons-material";
import { DynamicIcon } from "../../../../components/CustomIcons";
import { makeStyles } from "tss-react/mui";
import { getFileIconImage } from "../../../Files/FilesView/Results/FilesList/GridView";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Loader from "../../../../components/Loader";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const useStyles = makeStyles()((theme, _params, classes) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

export const analysisAndRetrieveAgents = [
  { name: "Data Analysis", icon: "TimelineRounded", color: "#4CAF50" },
];

function TagFileDialog({
  onClose,
  addTaggedAgent,
  addTaggedFiles,
  taggedFiles,
}) {
  const theme = useTheme();
  const { domains } = useSelector((state) => state.domain);
  const { completedFiles } = useSelector((state) => state.files);
  const initialData = [
    {
      type: "agents",
      items: domains
        .map((domain) => ({
          ...domain,
          name: domain.domain,
        }))
        .concat(analysisAndRetrieveAgents),
    },
    { type: "files", items: completedFiles },
  ];
  const agents = initialData[0].items;

  const handleSelectAgent = (agent) => {
    addTaggedAgent(agent);
    onClose();
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth={"xs"}
      PaperProps={{ sx: { borderRadius: 4 } }}>
      {/* <DialogTitle
        component={Box}
        display={"flex"}
        alignItems={"center"}
        sx={{ paddingX: 2, paddingY: 1.5 }}>
        <SearchRounded
          sx={{ color: theme.palette.action.active, marginRight: 1 }}
        />
        <InputBase
          fullWidth
          value={query}
          onChange={handleQueryChange}
          placeholder={"Search a agent / file"}
        />
      </DialogTitle> */}
      <DialogContent dividers sx={{ padding: 0 }}>
        <Box py={1}>
          <Box px={2}>
            <Typography variant="overline" color="text.secondary" gutterBottom>
              Agents
            </Typography>
          </Box>
          {agents.map((agent, i) => {
            return (
              <MenuItem key={i} onClick={() => handleSelectAgent(agent)}>
                {agent.icon && (
                  <DynamicIcon
                    iconName={agent.icon}
                    sx={{
                      color: agent.color
                        ? agent.color
                        : theme.palette.action.active,
                      marginRight: 1,
                    }}
                  />
                )}
                <Typography noWrap>{`${agent.name} Search Agent`}</Typography>
              </MenuItem>
            );
          })}
        </Box>
        <Divider />
        <FilesDialog
          addTaggedFiles={addTaggedFiles}
          taggedFiles={taggedFiles}
          closeParentDialog={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}

export function FilesDialog({ addTaggedFiles, taggedFiles, onClose }) {
  const { classes } = useStyles();
  const { completedFiles } = useSelector((state) => state.files);
  const theme = useTheme();
  const [selectedFiles, setSelectedFiles] = useState(taggedFiles);
  const [filteredFiles, setFilteredFiles] = useState(completedFiles);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setSelectedFiles(taggedFiles);
  }, [taggedFiles]);

  const handleQueryChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    let filteredFiles = completedFiles;
    if (query) {
      filteredFiles = completedFiles.filter((file) =>
        file.name.toLowerCase().includes(value.toLowerCase())
      );
    }
    setFilteredFiles(filteredFiles);
  };

  const handleSelectAllFiles = () => {
    setSelectedFiles(filteredFiles);
  };

  const handleUnselectAllFiles = () => {
    setSelectedFiles([]);
  };

  const handleAllCheckbox = (e) => {
    if (e.target.checked) {
      handleSelectAllFiles();
    } else {
      handleUnselectAllFiles();
    }
  };

  const handleFileCheckbox = (isSelected, file) => {
    if (!isSelected) {
      handleSelectFile(file);
    } else {
      handleUnselectFile(file);
    }
  };

  const handleSelectFile = (file) => {
    setSelectedFiles([...selectedFiles, file]);
  };

  const handleUnselectFile = (file) => {
    setSelectedFiles(selectedFiles.filter((f) => f.id !== file.id));
  };

  const handleSelectButton = () => {
    addTaggedFiles(selectedFiles);
    onClose();
  };

  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth maxWidth={"md"}>
        <DialogTitle component={Box} sx={{ padding: 0 }}>
          <Box
            px={3}
            py={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Typography variant="h6">Files Filter</Typography>
            <IconButton onClick={onClose}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider />
          <Box p={2} display={"flex"} alignItems={"center"}>
            <Checkbox
              checked={selectedFiles.length === filteredFiles.length}
              onChange={handleAllCheckbox}
              sx={{
                "&.Mui-checked": {
                  color: theme.palette.text.primary,
                },
              }}
            />
            {selectedFiles.length > 0 &&
            selectedFiles.length < filteredFiles.length ? (
              <Box display={"flex"} alignItems={"center"}>
                <span style={{ fontSize: 14 }}>✅</span>
                <Typography variant="body2" sx={{ ml: 0.5 }}>
                  {selectedFiles.length}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2">All</Typography>
            )}

            <Box mr={1} />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search files"
              size={"small"}
              value={query}
              onChange={handleQueryChange}
              InputProps={{
                startAdornment: (
                  <SearchRounded
                    sx={{ mr: 1, color: theme.palette.action.active }}
                  />
                ),
                style: { borderRadius: 8 },
              }}
            />
          </Box>
          {selectedFiles.length > 0 && (
            <Box
              display={"flex"}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={1}
              px={2}
              pb={2}
              maxHeight={100}
              overflow={"scroll"}>
              {selectedFiles.map((file) => (
                <Chip
                  size="small"
                  label={file.name}
                  icon={
                    <img
                      src={getFileIconImage(file.file_type)}
                      alt="file_icon"
                      width={16}
                      height={16}
                      style={{ borderRadius: "50%" }}
                    />
                  }
                  onDelete={() => handleUnselectFile(file)}
                  sx={{ maxWidth: 200, fontWeight: 400 }}
                />
              ))}
              <Button
                variant="text"
                size="small"
                onClick={handleUnselectAllFiles}>
                <span className={classes.gradientText}>Clear all</span>
              </Button>
            </Box>
          )}
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ padding: 0, overflow: "hidden", display: "flex" }}>
          <Grid
            container
            sx={{
              flex: 1,
            }}>
            <Grid
              item
              md={6}
              sx={{
                padding: 2,
                height: "100%",
                overflowY: "auto",
                borderRight: 1,
                borderColor: "divider",
              }}>
              <Box sx={{ height: "100%" }}>
                {filteredFiles.map((file, index) => {
                  const { name } = file;
                  const isSelected = selectedFiles.includes(file);
                  return (
                    <Tooltip key={index} title={name} arrow>
                      <MenuItem
                        onClick={() => handleFileCheckbox(isSelected, file)}
                        sx={{
                          // maxWidth: "100%",
                          // background: index % 2 === 0 ? "#F5F5F5" : "white",
                          paddingX: 2,
                          borderRadius: 1,
                          paddingLeft: 0,
                        }}>
                        <ListItemIcon>
                          <Checkbox
                            checked={isSelected}
                            sx={{
                              "&:hover": { background: "transparent" },
                              "&.Mui-checked": {
                                color: theme.palette.text.primary,
                              },
                            }}
                          />
                        </ListItemIcon>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          maxWidth={"calc(100% - 36px)"}>
                          <img
                            src={getFileIconImage(file.file_type)}
                            alt="file_icon"
                            width={16}
                            height={16}
                          />
                          <Box mr={1} />
                          <Typography noWrap variant="body2">
                            {name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Tooltip>
                  );
                })}
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                height: "100%",
                overflowY: "auto",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}>
              {selectedFiles.length > 0 ? (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  {/* Background stacked papers */}
                  {[...Array(Math.min(3, selectedFiles.length - 1))].map(
                    (_, index) => (
                      <Box
                        key={index}
                        sx={{
                          position: "absolute",
                          width: 288,
                          height: 400,
                          backgroundColor: "background.paper",
                          borderRadius: 1,
                          boxShadow: 1,
                          transform: `rotate(${index % 2 === 0 ? 4 : -4}deg)`,
                          zIndex: 3 - index,
                        }}
                      />
                    )
                  )}

                  {/* Top PDF preview */}
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 4,
                    }}>
                    {selectedFiles[0].file_type === "image" ? (
                      <ImagePreview file={selectedFiles[0]} />
                    ) : selectedFiles[0].file_type === "pdf" ? (
                      <PDFPreview file={selectedFiles[0]} />
                    ) : (
                      <NoPreview />
                    )}
                  </Box>

                  {selectedFiles.length > 1 && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        position: "absolute",
                        bottom: -30,
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 5,
                      }}>
                      {selectedFiles.length} files selected
                    </Typography>
                  )}
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}>
                  <img
                    src="/images/preview_image.png"
                    alt="preview"
                    style={{
                      maxWidth: 288,
                    }}
                  />
                  <Typography variant="subtitle2">
                    Please select a file to preview.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            <span className={classes.gradientText}>Cancel</span>
          </Button>
          <Button variant="contained" onClick={handleSelectButton}>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function NoPreview() {
  return (
    <Box
      sx={{
        maxWidth: 288,
        width: "100%",
        position: "relative",
        background: "background.paper",
        borderRadius: 1,
      }}>
      <img src="/images/preview_image.png" alt="preview" width={"100%"} />
      <Typography
        variant="subtitle2"
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          textAlign: "center",
        }}>
        No preview available
      </Typography>
    </Box>
  );
}

function ImagePreview({ file }) {
  return <img src={file["file_url"]} alt="preview" width={288} />;
}

function PDFPreview({ file }) {
  return (
    <Document
      className={"documentBackground"}
      file={file["file_url"]}
      loading={
        <Box
          sx={{
            width: 288,
            height: 400,
            position: "relative",
            background: "background.paper",
            borderRadius: 1,
          }}>
          <Loader />
        </Box>
      }>
      <Page
        className={"canvasBackground"}
        pageNumber={1}
        width={288}
        renderTextLayer={false}
        renderAnnotationLayer={false}
      />
    </Document>
  );
}

export default TagFileDialog;
