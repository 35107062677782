import React from "react";
import { Box, Chip, IconButton, Typography, useTheme } from "@mui/material";
import {
  CloseRounded,
  AssistantRounded,
  TimelineRounded,
  ImageSearchRounded,
  SchemaRounded,
  MessageRounded,
} from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { GradientIconAccent } from "../../../../components/GradientIcon";
import { getUserDetails } from "../../../../core/storage/localStorage";

const useStyles = makeStyles()((theme, _params, classes) => ({
  iconButton: {
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: "0px 4px 2px -2px rgba(0, 0, 0, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.06)",
    background: "white",
    padding: 11,
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

export const chatAgents = [
  {
    name: "QnA",
    key: "qna",
    icon: MessageRounded,
    color: "#9C27B0",
  },
  // {
  //   name: "Data Analysis",
  //   key: "data_analysis",
  //   icon: TimelineRounded,
  //   color: "#66BB6A",
  // },
  // {
  //   name: "Fetch Entity",
  //   key: "checklist_search",
  //   icon: ImageSearchRounded,
  //   color: "#2196F3",
  // },
  {
    name: "P&ID Assist",
    key: "pid_assist",
    icon: SchemaRounded,
    color: "#00BFA5",
  },
];

function NewSessionDialog({
  queryContainerHeight,
  onChatAgentChange,
  isNewSessionDialogOpen,
  setIsNewSessionDialogOpen,
}) {
  const theme = useTheme();
  const user = getUserDetails();
  const { classes } = useStyles();

  return (
    <Box>
      {!isNewSessionDialogOpen && (
        <IconButton
          sx={{ marginRight: 2, marginLeft: -1.5 }}
          className={classes.iconButton}
          onClick={() => setIsNewSessionDialogOpen(true)}
        >
          <GradientIconAccent Icon={AssistantRounded} />
        </IconButton>
      )}
      {isNewSessionDialogOpen && (
        <Box
          sx={{
            zIndex: 1000,
            position: "absolute",
            bottom: queryContainerHeight - 24,
            right: 20,
            left: 24,
            borderRadius: 2,
            height: `calc(100vh - 50px)`,
          }}
        >
          <Box display={"flex"} flexDirection={"column"} height={"100%"}>
            <Box
              flex={1}
              width={"100%"}
              sx={{
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 1) 100%)",
              }}
            />
            <Box
              sx={{
                background: "white",
                display: "flex",
                alignItems: "end",
              }}
            >
              <IconButton
                className={classes.iconButton}
                sx={{ marginLeft: -1.5 }}
                onClick={() => setIsNewSessionDialogOpen(false)}
              >
                <CloseRounded />
              </IconButton>
              <Box ml={2}>
                <Box display="flex" alignItems="center" mb={3}>
                  <IconButton
                    sx={{
                      background:
                        "linear-gradient(291deg, rgba(239, 143, 226, 0.12) 1.52%, rgba(176, 143, 239, 0.12) 43.76%, rgba(154, 143, 239, 0.12) 86.01%)",
                      "&:hover": {
                        background:
                          "linear-gradient(291deg, rgba(239, 143, 226, 0.12) 1.52%, rgba(176, 143, 239, 0.12) 43.76%, rgba(154, 143, 239, 0.12) 86.01%)",
                      },
                    }}
                  >
                    <GradientIconAccent Icon={AssistantRounded} />
                  </IconButton>
                  <Box mr={2} />
                  <Typography variant="h5">
                    <span className={classes.gradientText}>Assistant</span>
                  </Typography>
                </Box>
                <Typography variant="h6">
                  Hi {user ? user["name"] : "there"}! How can I help you today?
                </Typography>
                <Box mb={5} />
                <Box display={"flex"} flexWrap={"wrap"} columnGap={1.5}>
                  {chatAgents.map((agent, i) => {
                    const { name, icon, color } = agent;
                    const Icon = icon;
                    return (
                      <Chip
                        size="medium"
                        key={name}
                        label={name}
                        icon={
                          <Icon
                            sx={{
                              color: `${color} !important`,
                              marginLeft: "12px !important",
                            }}
                          />
                        }
                        sx={{
                          borderRadius: 25,
                          fontWeight: 500,
                          height: 48,
                          border: `1px solid ${theme.palette.action.hover}`,
                          background: "white",
                          boxShadow:
                            "0px 3px 1px -2px rgba(0, 0, 0, 0.08), 0px 2px 2px 0px rgba(0, 0, 0, 0.08)",
                        }}
                        onClick={() => onChatAgentChange(agent)}
                      />
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default NewSessionDialog;
