import {
  addMessageSuccess,
  createSessionSuccess,
  deleteFeedbackSuccess,
  deleteSessionSuccess,
  getAllFeedbackFailure,
  getAllFeedbackRequest,
  getAllFeedbackSuccess,
  getSessionMessagesFailure,
  getSessionMessagesInitiate,
  getSessionMessagesSuccess,
  getSessionsFailure,
  getSessionsInitiate,
  getSessionsSuccess,
  recordFeedbackSuccess,
  resetSessionMessagesSuccess,
  setSelectedSessionSuccess,
  updateMessageSuccess,
  updateSessionSuccess,
} from "../events/sessionEvents";
import sessionService from "../api/sessionService";

export function setSelectedSession(session) {
  return (dispatch) => {
    dispatch(setSelectedSessionSuccess(session));
  };
}

export function createSession(accountId, domainId = "", chatMode = "", fileId) {
  return async (dispatch) => {
    try {
      const response = await sessionService.createSession(accountId, domainId, chatMode, fileId);
      dispatch(createSessionSuccess({ ...response, title: "New Session" }));
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function updateSession(session) {
  return async (dispatch) => {
    try {
      await sessionService.updateSession(session);
      dispatch(updateSessionSuccess(session));
    } catch (error) {
      throw error;
    }
  };
}

export function deleteSession(sessionId) {
  return async (dispatch) => {
    try {
      await sessionService.deleteSession(sessionId);
      dispatch(deleteSessionSuccess(sessionId));
    } catch (error) {
      throw error;
    }
  };
}

export function getAllSessions(domainId = "", chatMode = "") {
  return async (dispatch) => {
    try {
      dispatch(getSessionsInitiate());
      const response = await sessionService.getAllSessions(domainId, chatMode);
      dispatch(getSessionsSuccess(response["conversations"] || []));
      return response["conversations"] || [];
    } catch (error) {
      dispatch(getSessionsFailure());
      throw error;
    }
  };
}

export function getSessionMessages(sessionId) {
  return async (dispatch) => {
    try {
      dispatch(getSessionMessagesInitiate());
      const response = await sessionService.getSessionMessages(sessionId);
      return response;
    } catch (error) {
      dispatch(getSessionMessagesFailure());
      throw error;
    }
  };
}

export function recordFeeback(messageId, feedbackData) {
  return async (dispatch) => {
    try {
      await sessionService.recordFeedback(messageId, feedbackData);
      dispatch(recordFeedbackSuccess(feedbackData));
    } catch (error) {
      throw error;
    }
  };
}

export function deleteFeedback(messageId) {
  return async (dispatch) => {
    try {
      await sessionService.deleteFeedback(messageId);
      dispatch(deleteFeedbackSuccess(messageId));
    } catch (error) {
      throw error;
    }
  };
}

export function getAllFeedback() {
  return async (dispatch) => {
    try {
      dispatch(getAllFeedbackRequest());
      const response = await sessionService.getAllFeedback();
      dispatch(getAllFeedbackSuccess(response.feedback_reports));
      return response;
    } catch (error) {
      const serializedError = {
        message: error.message || "Unknown error",
        status: error.response?.status || "No status",
        statusText: error.response?.statusText || "No status text",
      };
      dispatch(getAllFeedbackFailure(serializedError));
      throw error;
    }
  };
}

export async function getPromptQuestions() {
  try {
    const response = await sessionService.getPromptQuestions();
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getPipelineLogs(questionId) {
  try {
    const response = await sessionService.getPipelineLogs(questionId);
    return response;
  } catch (error) {
    throw error;
  }
}

export function getImage(imageURL) {
  return async (dispatch) => {
    try {
      const response = await sessionService.getImage(imageURL);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function bookmarkOrUnmarkMessage(message) {
  return async (dispatch) => {
    try {
      await sessionService.bookmarkOrUnmarkMessage(message["query"]);
      dispatch(updateMessageSuccess(message));
    } catch (error) {
      throw error;
    }
  };
}

export function getBookmarkedMessages() {
  return async (dispatch) => {
    try {
      const response = await sessionService.getBookmarkedMessages();
      return response;
    } catch (error) {
      throw error;
    }
  };
}
