import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Alert,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { getAllFeedback } from "../../core/repo/sessionRepo";
import Page from "../../components/Page";
import { ThumbDownAltRounded, ThumbUpAltRounded } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { ASSISTANT_URL } from "../../utils/routes";
import FeedbackDetail from "./ViewFeedbackDetail";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  feedbackCard: {
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: theme.shadows[4],
    },
  },
  feedbackHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  feedbackContent: {
    marginTop: theme.spacing(2),
  },
  feedbackChip: {
    margin: theme.spacing(0.5),
  },
  highlightedSection: {
    backgroundColor: theme.palette.primary.lighter,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  tabsContainer: {
    marginBottom: theme.spacing(3),
  },
  questionContent: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  answerContent: {
    color: theme.palette.text.secondary,
  },
  dateText: {
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
  },
  ratingIcon: {
    marginRight: theme.spacing(1),
  },
}));

function FeedbackCard({ feedback, onClick }) {
  const { classes } = useStyles();
  const theme = useTheme();

  if (!feedback) return null;

  const isPositive = feedback.rating === "positive" || feedback.rating === "good";

  const formatDate = (dateString) => {
    if (!dateString) return "Unknown date";
    try {
      console.log("Raw date string:", dateString);

      // Ensure the date string is properly formatted for UTC
      let date;
      if (dateString.includes("T")) {
        // If it's an ISO string format
        date = new Date(dateString);
      } else {
        // Try to parse as UTC date
        date = new Date(dateString + "T00:00:00Z");
      }

      console.log("Parsed date object:", date);

      if (isNaN(date.getTime())) {
        console.error("Invalid date parsing result for:", dateString);
        return "Invalid date";
      }

      const formatted = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(date);

      console.log("Formatted date:", formatted);
      return formatted;
    } catch (error) {
      console.error("Error formatting date:", error, "for date string:", dateString);
      return "Invalid date";
    }
  };

  const formatFeedbackTags = (feedbackString) => {
    if (!feedbackString) return [];
    try {
      return feedbackString
        .split(",")
        .map((tag) => tag.trim())
        .filter(Boolean);
    } catch (error) {
      console.error("Error formatting feedback tags:", error);
      return [];
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "success";
      case "rejected":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <Card className={classes.feedbackCard} onClick={onClick}>
      <CardContent>
        <Box className={classes.feedbackHeader}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              {isPositive ? (
                <ThumbUpAltRounded
                  className={classes.ratingIcon}
                  sx={{ color: theme.palette.success.main }}
                />
              ) : (
                <ThumbDownAltRounded
                  className={classes.ratingIcon}
                  sx={{ color: theme.palette.error.main }}
                />
              )}
              <Box>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6">{feedback.question_content}</Typography>
                  {feedback.approval_status && (
                    <Chip
                      label={feedback.approval_status}
                      color={getStatusColor(feedback.approval_status)}
                      size="small"
                      sx={{ ml: 1 }}
                    />
                  )}
                </Box>
                <Typography className={classes.dateText}>
                  {formatDate(feedback.updated_at)}
                </Typography>
              </Box>
            </Box>
            {!isPositive && feedback.partial && (
              <Typography variant="caption" color="error" sx={{ ml: 4 }}>
                Partially Incorrect
              </Typography>
            )}
          </Box>
        </Box>

        <Divider />

        <Box className={classes.feedbackContent}>
          <Typography variant="subtitle2">Feedback:</Typography>

          {feedback.feedback && (
            <Box mt={0}>
              <Box mt={0}>
                {formatFeedbackTags(feedback.feedback).map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    className={classes.feedbackChip}
                    size="small"
                    color={isPositive ? "success" : "error"}
                    variant="outlined"
                  />
                ))}
              </Box>
            </Box>
          )}

          {feedback.description && (
            <Box mt={2}>
              <Typography variant="subtitle2">Comment:</Typography>
              <Typography>{feedback.description}</Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

function ViewFeedback() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const { feedbackReports, isFeedbackLoading } = useSelector((state) => state.session);

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        await dispatch(getAllFeedback());
        setError(null);
      } catch (err) {
        console.error("Failed to fetch feedback:", err);
        setError("Failed to load feedback. Please try again later.");
      }
    };

    fetchFeedback();
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFeedbackClick = (feedback) => {
    setSelectedFeedback(feedback);
    setDetailDialogOpen(true);
  };

  const handleCloseDetailDialog = () => {
    setDetailDialogOpen(false);
  };

  const handleDeleteFeedback = (feedbackId) => {
    // The feedback will be removed from the state by the reducer
    setDetailDialogOpen(false);
  };

  const positiveReports =
    feedbackReports?.filter(
      (report) => report?.rating === "positive" || report?.rating === "good"
    ) || [];

  const negativeReports =
    feedbackReports?.filter(
      (report) => report?.rating === "negative" || report?.rating === "bad"
    ) || [];

  const displayReports = tabValue === 0 ? positiveReports : negativeReports;

  // Sort reports by updated_at in descending order
  const sortedReports = [...displayReports].sort((a, b) => {
    const dateA = new Date(a.updated_at || 0); // fallback to earliest possible date if no updated_at
    const dateB = new Date(b.updated_at || 0);
    return dateB - dateA;
  });

  return (
    <Page title="Feedback">
      <Container maxWidth="lg" sx={{ overflow: "auto" }}>
        {error && (
          <Box mb={3}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        <Box className={classes.tabsContainer}>
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label={`Positive Feedback (${positiveReports.length})`} id="positive-tab" />
            <Tab label={`Negative Feedback (${negativeReports.length})`} id="negative-tab" />
          </Tabs>
        </Box>

        {isFeedbackLoading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <Typography>Loading feedback...</Typography>
          </Box>
        ) : error ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <Typography color="error">Error loading feedback</Typography>
          </Box>
        ) : displayReports.length === 0 ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <Typography>No feedback available</Typography>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {sortedReports.map((feedback) => (
              <Grid item xs={12} key={feedback.id}>
                <FeedbackCard feedback={feedback} onClick={() => handleFeedbackClick(feedback)} />
              </Grid>
            ))}
          </Grid>
        )}

        <FeedbackDetail
          feedback={selectedFeedback}
          open={detailDialogOpen}
          onClose={handleCloseDetailDialog}
          onDelete={handleDeleteFeedback}
        />
      </Container>
    </Page>
  );
}

export default ViewFeedback;
