import { produce } from "immer";
import {
  ADD_MESSAGE_SUCCESS,
  CREATE_SESSION_SUCCESS,
  DELETE_FEEDBACK_SUCCESS,
  DELETE_SESSION_SUCCESS,
  GET_ALL_FEEDBACK_FAILURE,
  GET_ALL_FEEDBACK_REQUEST,
  GET_ALL_FEEDBACK_SUCCESS,
  GET_SESSIONS_FAILURE,
  GET_SESSIONS_INITIATE,
  GET_SESSIONS_SUCCESS,
  GET_SESSION_MESSAGES_FAILURE,
  GET_SESSION_MESSAGES_INITIATE,
  GET_SESSION_MESSAGES_SUCCESS,
  RECORD_FEEDBACK_SUCCESS,
  RESET_SESSION_MESSAGES_SUCCESS,
  SET_CHAT_MODE_SUCCESS,
  SET_SELECTED_DATA_ANALYSIS_FILE_SUCCESS,
  SET_SELECTED_DOMAIN_SUCCESS,
  SET_SELECTED_FILES_SUCCESS,
  SET_SELECTED_SESSION_SUCCESS,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_SESSION_SUCCESS,
  UPDATE_FEEDBACK_STATUS_SUCCESS,
} from "../events/sessionEvents";
import { setChatModeLocalStorage, setSelectedDomainLocalStorage } from "../storage/localStorage";

const initialState = {
  chatMode: "general_qna",
  selectedDomain: null,
  sessions: [],
  messages: [],
  selectedFiles: [],
  selectedDataAnalysisFile: null,
  selectedSession: null,
  isSessionsLoading: false,
  isSessionFetched: false,
  isMessagesLoading: false,
  isMessagesFetched: false,
  feedbackReports: [],
  isFeedbackLoading: false,
  isFeedbackFetched: false,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_DOMAIN_SUCCESS: {
      const { selectedDomain } = action.payload;
      return produce(state, (draft) => {
        draft.selectedDomain = selectedDomain;
        setSelectedDomainLocalStorage(selectedDomain);
      });
    }

    case SET_SELECTED_DATA_ANALYSIS_FILE_SUCCESS: {
      const { selectedDataAnalysisFile } = action.payload;
      return produce(state, (draft) => {
        draft.selectedDataAnalysisFile = selectedDataAnalysisFile;
      });
    }

    case SET_SELECTED_FILES_SUCCESS: {
      const { selectedFiles } = action.payload;
      return produce(state, (draft) => {
        draft.selectedFiles = selectedFiles;
      });
    }

    case SET_CHAT_MODE_SUCCESS: {
      const { chatMode } = action.payload;
      return produce(state, (draft) => {
        draft.chatMode = chatMode;
        setChatModeLocalStorage(chatMode);
      });
    }

    case SET_SELECTED_SESSION_SUCCESS: {
      const { session } = action.payload;
      return produce(state, (draft) => {
        draft.selectedSession = session;
      });
    }

    case GET_SESSIONS_INITIATE: {
      return produce(state, (draft) => {
        draft.isSessionsLoading = true;
      });
    }

    case GET_SESSIONS_SUCCESS: {
      const { sessions } = action.payload;
      return produce(state, (draft) => {
        draft.sessions = sessions;
        draft.isSessionsLoading = false;
        draft.isSessionFetched = true;
      });
    }

    case GET_SESSIONS_FAILURE: {
      return produce(state, (draft) => {
        draft.isSessionsLoading = false;
        draft.isSessionFetched = true;
      });
    }

    case GET_SESSION_MESSAGES_INITIATE: {
      return produce(state, (draft) => {
        draft.isMessagesLoading = true;
        draft.isMessagesFetched = false;
      });
    }

    case GET_SESSION_MESSAGES_SUCCESS: {
      const { messages } = action.payload;
      return produce(state, (draft) => {
        draft.messages = messages;
        draft.isMessagesLoading = false;
        draft.isMessagesFetched = true;
      });
    }

    case GET_SESSION_MESSAGES_FAILURE: {
      return produce(state, (draft) => {
        draft.isMessagesLoading = false;
        draft.isMessagesFetched = true;
      });
    }

    case RESET_SESSION_MESSAGES_SUCCESS: {
      return produce(state, (draft) => {
        draft.messages = [];
        draft.isMessagesFetched = false;
      });
    }

    case CREATE_SESSION_SUCCESS: {
      const { session } = action.payload;
      return produce(state, (draft) => {
        draft.sessions = [session, ...state.sessions];
      });
    }

    case UPDATE_SESSION_SUCCESS: {
      const { session } = action.payload;
      return produce(state, (draft) => {
        const sessions = state.sessions.map((s) => {
          if (s["id"] === session["id"]) {
            return { ...s, ...session };
          }
          return s;
        });
        draft.sessions = sessions;
      });
    }

    case DELETE_SESSION_SUCCESS: {
      const { sessionId } = action.payload;
      return produce(state, (draft) => {
        const sessions = state.sessions.filter((s) => s["id"] !== sessionId);
        draft.sessions = sessions;
      });
    }

    case ADD_MESSAGE_SUCCESS: {
      const { message, parentMessage } = action.payload;
      const sessionId = message["query"]["conversation_id"];
      return produce(state, (draft) => {
        let messages = [...state.messages, message];
        if (parentMessage) {
          const threadMessages = parentMessage["query"]["threads"] || [];
          const tempParentMessage = {
            ...parentMessage,
            query: {
              ...parentMessage["query"],
              threads: [...threadMessages, message],
            },
          };
          messages = state.messages.map((msg) => {
            if (msg["query"]["message_id"] === parentMessage["query"]["message_id"]) {
              return tempParentMessage;
            }
            return msg;
          });
        }
        draft.sessions = state.sessions.map((session) => {
          if (session["id"] === sessionId) {
            return { ...session, updated_at: new Date().toISOString() };
          }
          return session;
        });
        draft.messages = messages;
      });
    }

    case UPDATE_MESSAGE_SUCCESS: {
      const { message } = action.payload;
      const { answer, query } = message;
      return produce(state, (draft) => {
        const messages = state.messages.map((m) => {
          if (m["query"]["message_id"] === query["message_id"]) {
            return {
              query: { ...m["query"], is_bookmarked: query["is_bookmarked"] },
              answer: {
                ...m["answer"],
                is_bookmarked: answer["is_bookmarked"],
              },
            };
          } else if (m["query"]["threads"]) {
            const threadMessages = m["query"]["threads"].map((msg) => {
              if (msg["query"]["message_id"] === query["message_id"]) {
                return {
                  query: {
                    ...msg["query"],
                    is_bookmarked: query["is_bookmarked"],
                  },
                  answer: {
                    ...msg["answer"],
                    is_bookmarked: answer["is_bookmarked"],
                  },
                };
              }
              return msg;
            });
            return {
              ...m,
              query: { ...m["query"], threads: threadMessages },
            };
          }
          return m;
        });
        draft.messages = messages;
      });
    }

    case RECORD_FEEDBACK_SUCCESS: {
      const { feedback } = action.payload;
      return produce(state, (draft) => {
        const messages = state.messages.map((m) => {
          if (m["query"]["message_id"] === feedback["message_id"]) {
            return {
              ...m,
              query: { ...m["query"], answer_feedback: feedback },
            };
          } else if (m["query"]["threads"]) {
            const threadMessages = m["query"]["threads"].map((msg) => {
              if (msg["query"]["message_id"] === feedback["message_id"]) {
                return {
                  ...msg,
                  query: { ...msg["query"], answer_feedback: feedback },
                };
              }
              return msg;
            });
            return {
              ...m,
              query: { ...m["query"], threads: threadMessages },
            };
          }
          return m;
        });
        draft.messages = messages;
      });
    }

    case DELETE_FEEDBACK_SUCCESS: {
      const { messageId } = action.payload;
      return produce(state, (draft) => {
        const messages = state.messages.map((m) => {
          if (m["query"]["message_id"] === messageId) {
            return {
              ...m,
              query: { ...m["query"], answer_feedback: null },
            };
          } else if (m["query"]["threads"]) {
            const threadMessages = m["query"]["threads"].map((msg) => {
              if (msg["query"]["message_id"] === messageId) {
                return {
                  ...msg,
                  query: { ...msg["query"], answer_feedback: null },
                };
              }
              return msg;
            });
            return {
              ...m,
              query: { ...m["query"], threads: threadMessages },
            };
          }
          return m;
        });
        draft.messages = messages;
        if (state.feedbackReports.length > 0) {
          draft.feedbackReports = state.feedbackReports.filter(
            (report) => report.message_id !== messageId
          );
        }
      });
    }

    case GET_ALL_FEEDBACK_REQUEST: {
      return produce(state, (draft) => {
        draft.isFeedbackLoading = true;
        draft.isFeedbackFetched = false;
      });
    }

    case GET_ALL_FEEDBACK_SUCCESS: {
      const { feedbackReports } = action.payload;
      return produce(state, (draft) => {
        draft.feedbackReports = feedbackReports;
        draft.isFeedbackLoading = false;
        draft.isFeedbackFetched = true;
      });
    }

    case GET_ALL_FEEDBACK_FAILURE: {
      return produce(state, (draft) => {
        draft.isFeedbackLoading = false;
        draft.isFeedbackFetched = true;
        draft.feedbackReports = [];
      });
    }

    case UPDATE_FEEDBACK_STATUS_SUCCESS: {
      const { messageId, approvalStatus } = action.payload;
      return produce(state, (draft) => {
        if (state.feedbackReports.length > 0) {
          draft.feedbackReports = state.feedbackReports.map((report) => {
            if (report.message_id === messageId) {
              return { ...report, approval_status: approvalStatus };
            }
            return report;
          });
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
