import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  useTheme,
  Divider,
  Grid,
  Chip,
  InputBase,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  CloseRounded,
  DeleteRounded,
  ErrorOutlineOutlined,
  NotesRounded,
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
  ThumbDownAltRounded,
  ThumbDownOffAltRounded,
  ThumbUpAltRounded,
  ThumbUpOffAlt,
} from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import HighlightText from "./HighlightText";
import { useDispatch } from "react-redux";
import { deleteFeedback, recordFeeback } from "../../../../core/repo/sessionRepo";
import { usePostHog } from "posthog-js/react";
import {
  DELETE_FEEDBACK_FAILURE,
  DELETE_FEEDBACK_SUCCESS,
  OPEN_FEEDBACK_DIALOG,
  SUBMIT_FEEDBACK_FAILURE,
  SUBMIT_FEEDBACK_SUCCESS,
} from "../../../../utils/posthogEvents";
import { GradientIconAccent } from "../../../../components/GradientIcon";

const useStyles = makeStyles()((theme, _params, classes) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  filled: {
    background: theme.palette.text.primary,
    color: "white",
    ":hover": {
      background: theme.palette.text.primary,
    },
  },
  icon: {
    cursor: "pointer",
    transition: "all 0.1s linear",
    ":hover": {
      fontSize: 30,
    },
  },
}));

const reasonGroups = [
  {
    label: "Answer",
    key: "answer",
    reasons: [
      { key: "not_relavant", label: "Not relevant" },
      { key: "too_long", label: "Too long" },
      { key: "not_accurate", label: "Not accurate" },
      { key: "incomplete", label: "Incomplete" },
      { key: "no_answer", label: "No Answer" },
    ],
  },
  {
    label: "Citations",
    key: "citations",
    reasons: [
      { key: "incomplete_citations", label: "Incomplete Citations" },
      { key: "incorrect_citations", label: "Wrong Citations" },
    ],
  },
];

function Feedback({ message }) {
  const theme = useTheme();
  const posthog = usePostHog();
  const { enqueueSnackbar } = useSnackbar();
  const [query, setQuery] = useState(message["query"]);
  const answerFeedback = query["answer_feedback"];

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setIsDialogOpen(true);
    posthog.capture(OPEN_FEEDBACK_DIALOG);
  };

  const submitFeedback = async (
    rating,
    highlightedSections,
    description,
    typeOfAnswer,
    feedbackChips
  ) => {
    const feedbackData = {
      message_id: query["message_id"],
      rating,
      highlighted_sections: highlightedSections,
      description,
      partial: typeOfAnswer === "part_of_answer",
      feedback: feedbackChips.toString(),
    };
    try {
      await dispatch(recordFeeback(query["message_id"], feedbackData));
      setQuery({ ...query, answer_feedback: feedbackData });
      enqueueSnackbar("Your feedback has been recorded", {
        variant: "success",
      });
      posthog.capture(SUBMIT_FEEDBACK_SUCCESS, { rating: rating });
      setIsDialogOpen(false);
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to record your feedback", {
        variant: "error",
      });
      posthog.capture(SUBMIT_FEEDBACK_FAILURE);
    }
  };

  const deleteAnswerFeedback = async () => {
    try {
      await dispatch(deleteFeedback(query["message_id"]));
      setQuery({ ...query, answer_feedback: null });
      enqueueSnackbar("Your feedback has been deleted", {
        variant: "success",
      });
      posthog.capture(DELETE_FEEDBACK_SUCCESS);
      setIsDialogOpen(false);
    } catch (err) {
      enqueueSnackbar("Failed to delete the feedback", {
        variant: "error",
      });
      posthog.capture(DELETE_FEEDBACK_FAILURE);
      console.log(err);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Tooltip title="Good Answer">
          <IconButton
            onClick={() => {
              if (answerFeedback) {
                deleteAnswerFeedback();
              } else {
                submitFeedback("good", [], "", null, []);
              }
            }}
            size="small"
            sx={{ color: theme.palette.action.active, marginRight: 1 }}
          >
            {answerFeedback && answerFeedback["rating"] === "good" ? (
              <GradientIconAccent Icon={ThumbUpAltRounded} sx={{ fontSize: 20 }} />
            ) : (
              <ThumbUpOffAlt fontSize="small" sx={{ fontSize: 18 }} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Answer not up to the mark">
          <IconButton onClick={handleOpen} size="small" sx={{ color: theme.palette.action.active }}>
            {answerFeedback && answerFeedback["rating"] === "bad" ? (
              <GradientIconAccent Icon={ThumbDownAltRounded} sx={{ fontSize: 20 }} />
            ) : (
              <ThumbDownOffAltRounded fontSize="small" sx={{ fontSize: 18 }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      {isDialogOpen && (
        <FeedbackDialog
          message={message}
          onClose={() => setIsDialogOpen(false)}
          submitFeedback={submitFeedback}
          deleteAnswerFeedback={deleteAnswerFeedback}
        />
      )}
    </>
  );
}

function FeedbackDialog({ message, onClose, submitFeedback, deleteAnswerFeedback }) {
  const { query, answer } = message;
  const answerFeedback = query["answer_feedback"];
  const { classes } = useStyles();
  const theme = useTheme();
  const [rating, setRating] = useState("bad");
  const [additionalFeedback, setAdditionalFeedback] = useState(
    answerFeedback ? answerFeedback["description"] : ""
  );

  let stateValue = [];
  if (answerFeedback && answerFeedback["feedback"] && answerFeedback["feedback"].length !== 0) {
    stateValue = answerFeedback["feedback"].split(",");
  }
  const [feedbackChips, setFeedbackChips] = useState(stateValue);
  const [step, setStep] = useState(0);
  const [typeOfAnswer, setTypeOfAnswer] = useState(
    answerFeedback ? (answerFeedback["partial"] ? "part_of_answer" : "complete_answer") : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const editorRef = useRef();
  const [isEntityAnswer, setIsEntityAnswer] = useState(false);

  useEffect(() => {
    const entityAnswer = answer["content_object"]["type"] === "entity";
    setIsEntityAnswer(entityAnswer);
    if (entityAnswer) {
      setTypeOfAnswer("complete_answer");
      setStep(1);
    }
  }, [answer]);

  const handleClickSubmitFeedback = () => {
    const highlightedSections = getHighlightedText();
    submitFeedback(
      rating,
      typeOfAnswer === "part_of_answer"
        ? highlightedSections
        : [{ text: answer["content_object"]["text"] }],
      additionalFeedback,
      typeOfAnswer,
      feedbackChips
    );
  };

  const handleClickFeedbackChip = (feedback) => {
    const isExists = feedbackChips.find((c) => c === feedback);
    let tempFeedbackChips = [...feedbackChips];
    if (!isExists) {
      tempFeedbackChips = [...tempFeedbackChips, feedback];
    } else {
      tempFeedbackChips = tempFeedbackChips.filter((c) => c !== feedback);
    }
    setFeedbackChips(tempFeedbackChips);
  };

  const getHighlightedText = () => {
    let formattedTextArr = [];
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();
      const contents = quill.getContents();

      contents.ops.forEach((op) => {
        if (op.attributes && op.attributes.background) {
          formattedTextArr = [...formattedTextArr, { text: op.insert }];
        }
      });
    }
    return formattedTextArr;
  };

  const clearSelection = () => {
    const quill = editorRef.current.getEditor();
    if (quill && typeOfAnswer === "part_of_answer") {
      quill.formatText(0, quill.getLength(), "background", false);
    }
    setFeedbackChips([]);
    setAdditionalFeedback("");
  };

  const handlePrev = () => {
    setStep(0);
  };

  const handleNext = () => {
    setStep(1);
  };

  const handleTypeOfAnswer = (typeOfAnswer) => {
    setTypeOfAnswer(typeOfAnswer);
    // handleNext();
  };

  const handleRating = (rating) => {
    setRating(rating);
    handlePrev();
  };

  // useEffect(() => {
  //   if (typeOfAnswer === "complete_answer" && editorRef.current) {
  //     const quill = editorRef.current.getEditor();
  //     quill.formatText(0, quill.getLength(), "background", "#FFF59D");
  //   }
  // }, [typeOfAnswer, step]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Answer Feedback</Typography>
        <Box display={"flex"} alignItems={"center"}>
          {answerFeedback && (
            <Tooltip title={"Remove Feedback"}>
              <IconButton onClick={deleteAnswerFeedback} sx={{ mr: 1 }}>
                <DeleteRounded />
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {rating === "bad" && (
          <>
            {step === 0 ? (
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"} p={1}>
                <Typography fontWeight={500} mb={2} variant="h6" textAlign={"center"}>
                  <span className={classes.gradientText}>
                    Is the issue with the complete answer or part of the answer?
                  </span>
                </Typography>
                <Box
                  bgcolor={theme.palette.background.light}
                  borderRadius={3}
                  p={1}
                  my={1}
                  maxWidth={400}
                  width={"100%"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleTypeOfAnswer("complete_answer")}
                  >
                    <Typography>Complete answer</Typography>
                    <IconButton>
                      {typeOfAnswer === "complete_answer" ? (
                        <RadioButtonCheckedRounded />
                      ) : (
                        <RadioButtonUncheckedRounded />
                      )}
                    </IconButton>
                  </Box>
                  <Divider sx={{ marginY: 0.5 }} />
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleTypeOfAnswer("part_of_answer")}
                  >
                    <Typography>Part of answer</Typography>
                    <IconButton>
                      {typeOfAnswer === "part_of_answer" ? (
                        <RadioButtonCheckedRounded />
                      ) : (
                        <RadioButtonUncheckedRounded />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"} p={1}>
                <Typography fontWeight={500} variant="h6">
                  <span className={classes.gradientText}>
                    {typeOfAnswer === "complete_answer"
                      ? "What is the issue with the answer?"
                      : "Please highlight parts of the answer and select the issue."}
                  </span>
                </Typography>
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={3}
                  alignItems={"stretch"}
                  mb={2}
                  mt={1}
                >
                  {!isEntityAnswer && (
                    <Grid item md={7}>
                      <Box mb={1} display={"flex"} alignItems={"center"}>
                        <NotesRounded sx={{ color: theme.palette.action.disabled }} />
                        <Box mr={1} />
                        <Typography variant="subtitle2" color={"textSecondary"}>
                          {typeOfAnswer === "complete_answer" ? "Answer" : "Highlight Answer"}
                        </Typography>
                      </Box>
                      <HighlightText
                        editorRef={editorRef}
                        message={answer["content_object"]["text"]}
                        typeOfAnswer={typeOfAnswer}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    md={isEntityAnswer ? 12 : 5}
                    display={"flex"}
                    flexDirection={"column"}
                    height={"100%"}
                  >
                    <Box mb={1} display={"flex"} alignItems={"center"}>
                      <ErrorOutlineOutlined sx={{ color: theme.palette.action.disabled }} />
                      <Box mr={1} />
                      <Typography variant="subtitle2" color={"textSecondary"}>
                        Select the issue
                      </Typography>
                    </Box>
                    <Box
                      flex={1}
                      // overflow={"auto"}
                      display={"flex"}
                      flexDirection={"column"}
                      bgcolor={theme.palette.background.light}
                      borderRadius={3}
                      p={2}
                    >
                      {reasonGroups.map((group) => {
                        const { label, key, reasons } = group;
                        return (
                          <Box key={key} mb={2}>
                            <Typography variant="caption" fontWeight={400} color={"textSecondary"}>
                              {label}
                            </Typography>
                            <Box mb={0.5} />
                            <Box
                              rowGap={1}
                              columnGap={1}
                              display={"flex"}
                              alignItems={"center"}
                              flexWrap={"wrap"}
                            >
                              {reasons.map((reason) => {
                                const { key, label } = reason;
                                const isSelected = feedbackChips.find((c) => c === key);
                                return (
                                  <Chip
                                    classes={{ filled: classes.filled }}
                                    variant={isSelected ? "filled" : "outlined"}
                                    key={key}
                                    label={label}
                                    onClick={() => handleClickFeedbackChip(key)}
                                  />
                                );
                              })}
                            </Box>
                          </Box>
                        );
                      })}
                      <InputBase
                        fullWidth
                        multiline
                        placeholder="Add additional feedback"
                        value={additionalFeedback}
                        onChange={(e) => setAdditionalFeedback(e.target.value)}
                        sx={{
                          flex: 1,
                          overflow: "auto",
                          alignItems: "start",
                          padding: 1,
                          borderRadius: 2,
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          background: "#f0f0f0",
                          fontSize: 13,
                          marginTop: 2,
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        )}
      </DialogContent>
      {rating && (
        <DialogActions sx={{ justifyContent: "space-between" }}>
          {step === 1 ? (
            <Button variant="text" startIcon={<ArrowBackIosRounded />} onClick={handlePrev}>
              <span className={classes.gradientText}>Prev</span>
            </Button>
          ) : (
            <Button
              sx={{ visibility: "hidden" }}
              variant="text"
              startIcon={<ArrowBackIosRounded />}
            >
              Filler
            </Button>
          )}
          {rating !== "good" && <Typography variant="body2">{step + 1} / 2</Typography>}
          {rating === "good" || step === 1 ? (
            <Box
              display={"flex"}
              flexDirection={{ md: "row", xs: "column" }}
              alignItems={{ xs: "end", md: "center" }}
            >
              <Button
                variant="text"
                size="small"
                onClick={clearSelection}
                sx={{ mr: { xs: 0, md: 3 } }}
                disabled={feedbackChips.length === 0}
              >
                Clear Selection
              </Button>
              <Button
                onClick={handleClickSubmitFeedback}
                variant="contained"
                color="primary"
                sx={{ mt: { xs: 1, md: 0 } }}
                disabled={feedbackChips.length === 0}
              >
                Submit Feedback
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIosRounded />}
              disabled={!typeOfAnswer}
              onClick={handleNext}
            >
              Next
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default Feedback;
